import React, { useState, useEffect } from "react";
import { Box, Spinner, Center } from "@chakra-ui/react";

const BackgroundImageBox = ({ imageUrl, children, ...props }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.src = imageUrl;
    image.onload = () => setImageLoaded(true);
  }, [imageUrl]);

  return (
    <Box position="relative" width="full" height="400px">
      {!imageLoaded && (
        <Center position="absolute" top="0" right="0" bottom="0" left="0">
          <Spinner size="md" />
        </Center>
      )}
      <Box
        h={{ base: "373px", md: "316px" }}
        mb={{ base: "36px", md: "96px" }}
        align="center"
        bgRepeat="no-repeat"
        bgPosition="center"
        bgSize="cover"
        zIndex="99"
        pos="relative"
        width="full"
        bgImage={imageLoaded ? imageUrl : "none"}
      >
        {children}
      </Box>
    </Box>
  );
};

export default BackgroundImageBox;

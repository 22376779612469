import HomePartnerAndMedia from "components/Home/HomePartnerAndMedia";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import HomeBlog from "../components/Home/HomeBlog";
import HomeCarSelect from "../components/Home/HomeCarSelect";
import HomeCompare from "../components/Home/HomeCompare";
import HomeFAQ from "../components/Home/HomeFAQ";
import HomeFeedback from "../components/Home/HomeFeedback";
import HomeHero from "../components/Home/HomeHero/HomeHero";
import HomeTutorial from "../components/Home/HomeTutorial";
import MainFooter from "../components/Layout/MainFooter";
import { reduceCarsToOneLevel, reduceCarsToTwoLevel } from "../lib/cars";
import api from "../store/api";
import { useSession } from "hooks/useSession";

function Home({
  cars = [],
  largeImgList = [],
  smallImgList = [],
  ownerSettings = [],
  deliveryZones = [],
  group,
}) {
  const router = useRouter();
  const { locale } = router;
  const isTw = locale === "zh-TW";
  const carsData = reduceCarsToTwoLevel(cars);
  const orgCarsData = reduceCarsToOneLevel(cars);

  return (
    <div>
      <HomeHero
        group={group}
        largeImgList={largeImgList}
        smallImgList={smallImgList}
        ownerSettings={ownerSettings}
        deliveryZones={deliveryZones}
      />
      <HomeCarSelect carsData={orgCarsData} />
      <HomeBlog />
      {/* <HomeDiscount /> */}
      <HomeTutorial />
      {/* <HomeServiceDistrict /> */}
      <HomeFAQ />
      {isTw && <HomeCompare carsData={carsData} />}
      {isTw && <HomeFeedback />}
      <HomePartnerAndMedia />
      {/* <HomeAction /> */}
      {/* <SwitchLang /> */}
      {/* {isTestTeam === false && <Calltoaction href={"#models"} />} */}
      <HomeCarSelect carsData={orgCarsData} />
      <MainFooter />
    </div>
  );
}

export async function getServerSideProps(context) {
  const { locale } = context;

  let group = context.req.cookies.UDRIVE_T_GOOGLEMAP_SEARCH_v3;

  if (!group) {
    const random = Math.random();
    group = "B";
    context.res.setHeader(
      "Set-Cookie",
      `UDRIVE_T_GOOGLEMAP_SEARCH_v3=${group}; Path=/; Max-Age=2592000`
    ); // 保存分組到 Cookie 中，此例中的 Max-Age 是30天
  }

  // Fetch Home Page Cars Data

  const carsResp = await api.getCars();
  const cars = carsResp?.data?.data || [];

  const ownerSettingsResp = await api.getAllOwnerSetting();
  const ownerSettings = ownerSettingsResp.data.data;
  const deliveryZonesResp = await api.getDeliveryZone();
  const deliveryZones = deliveryZonesResp.data.data;

  const bannerResp = await api.getBannerList(locale);
  const largeImgList = bannerResp.data.filter((item) => {
    if (item?.banner.large) {
      return true;
    }
  });
  const smallImgList = bannerResp.data.filter((item) => {
    if (item?.banner.small) {
      return true;
    }
  });

  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
      cars,
      largeImgList,
      smallImgList,
      ownerSettings,
      deliveryZones,
      group,
    },
  };
}

export default Home;

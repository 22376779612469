import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import HomeCard from "./Home/HomeCard";
import MyImage from "./MyImage";
import { COLORS_URL } from "lib/cars";

const PlanBox = ({
  title,
  label,
  pathname,
  query,
  buttonText,
  buttonColor,
  iconSrc,
  price,
  price_rt,
  price_rb,
  imagePosRight,
  imagePosBottom,
  imageH,
  description,
  colors = [],
  ...props
}) => {
  const { t } = useTranslation("common");
  const [isHover, hover] = useState(false);
  const router = useRouter();
  const { locale } = router;
  const isTw = locale === "zh-TW";
  return (
    <HomeCard
      w={{ base: "100%", mg: "46%", lg: "31%" }}
      cursor="pointer"
      mb="25px"
      mx={{ base: "auto", md: "10px" }}
      {...props}
    >
      <Link
        href={{
          pathname,
          query,
        }}
      >
        <a style={{ display: "block" }}>
          <Flex
            flexDir="column"
            align="start"
            style={{
              pointerEvents: buttonText === t("willOpen") ? "none" : "visible",
            }}
            w="100%"
            pos="relative"
            overflow="hidden"
            p="20px"
            py="15px"
            color="#000"
            textAlign="left"
            onMouseEnter={() => hover(true)}
            onMouseLeave={() => hover(false)}
            transition="0.3s ease"
            minH={{ base: "300px", md: "280px" }}
          >
            <MyImage
              alt="udrive"
              pos="absolute"
              right={imagePosRight ? imagePosRight : "-110px"}
              bottom={imagePosBottom ? imagePosBottom : "-10px"}
              transform={isHover ? "translateX(-5%)" : "translateX(0%)"}
              transition="0.3s ease"
              h={imageH ? imageH : "125px"}
              src={iconSrc}
            />
            <Text fontSize="25px" fontWeight="bolder">
              {isTw ? title : title.replace("座", " Seater")}
            </Text>
            <Box
              letterSpacing="auto"
              fontSize="18px"
              pb="15px"
              boxShadow="0 1px 0 0 rgba(0, 0, 0, .1)"
              mb="30px"
              flex="1 1 auto"
            >
              <Text minH="50px" opacity="0.8">
                {description}
              </Text>
              <HStack mt="10px">
                {colors.map((c) => (
                  <Box w="22px" h="22px" key={c}>
                    <MyImage src={COLORS_URL[c]} alt={c} />
                  </Box>
                ))}
              </HStack>
            </Box>
            {/* <Flex
              alignItems="center"
              mt="10px"
              zIndex="1"
              pos="relative"
              w="100%"
            >
              <Text fontSize="40px" fontWeight="bolder" lineHeight={1} mr="5px">
                <Text as="span">{price}</Text>
                <Text as="span" fontSize="16px">
                  /{t("days")}
                </Text>
              </Text>
              <Box ml="5px" flex="1 0 100px" w="100px">
                <Text fontSize="13px" textDecoration="line-through">
                  {price_rt}
                </Text>
                <Text fontSize="13px">{price_rb}</Text>
              </Box>
            </Flex> */}
            <Box
              px="30px"
              py="8px"
              ml="-5px"
              fontSize="18px"
              borderRadius="30px"
              fontWeight="bold"
              color="#fff"
              bgColor={buttonColor ? buttonColor : "#475aff"}
              border="none"
              display="inline-block"
              mt="30px"
              opacity={isHover ? 1 : 0.8}
              transition="0.3s ease"
            >
              {buttonText}
            </Box>
          </Flex>
        </a>
      </Link>
    </HomeCard>
  );
};

export default PlanBox;
